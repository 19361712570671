import React from "react";
import queryString from 'query-string';
import Layout from "../components/Layout"
import SEO from "../components/seo";
import Content from '../views/Blog/Article';
import { strapiBlogpostUrl } from './constants';

const getId = query => queryString.parse(query).ref

const getPost = id => fetch(strapiBlogpostUrl + '/' + id, {
  method: 'GET',
  header: {
    'Content-Type': 'application/json'
  }
}).then(res => res.json())

export default class Blogpost extends React.Component {
  constructor() {
    super();
    this.state = {
      post: null
    }
  }

  componentDidMount() {
    const _id = getId(this.props.location.search);
    getPost(_id).then(post => {
      if (!post.error) this.setState({ post });
    });
  }

  
  render() {
    const { post } = this.state;
    return (
    <Layout overlay page='blog'
      url='_url:blog'>
      <SEO title='Article'
        lang='da-DK'
        description='Staged article'
        meta={[{
          name: 'keywords',
          content: 'staged article'
        }]}
        location={this.props.location}/>
      <Content post={post}/>
    </Layout>
    );
  }
}